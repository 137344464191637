import * as React from 'react';
import i18n from "../../../assets/i18n";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

export default function MonthlyCandidatesByClient({ data: statistics }) {
  const dinamicMonthNameColumns = statistics?.column_titles ? Object.values(statistics?.column_titles) : []
  const dinamicMonthKeyColumns = statistics?.column_titles ? Object.keys(statistics?.column_titles) : []
  const avgCounter = statistics?.avg_counter ?? 12
  const from = statistics.from
  const to = statistics.to
  const clientStatisticRows = statistics?.rows ? Object.values(statistics?.rows) : []

  return (
    <>
      <TableContainer sx={{overflowY: 'auto', maxWidth: '100%'}} component={Paper}>
        <Table size="small" aria-label="a dense table" sx={{minWidth: 'max-content'}}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={avgCounter + 3} align='center'><strong>Candidatos/Meses ({from} al {to})</strong></TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              {dinamicMonthNameColumns.map(function (text) {
                return (<TableCell><strong>{text}</strong></TableCell>)
              })}
              <TableCell><strong>Average</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clientStatisticRows.map(function (clientStats) {
              let total = 0
              return (
                <TableRow>
                  <TableCell>{ clientStats?.client_name ?? '-' }</TableCell>
                  {dinamicMonthKeyColumns.map(function (monthKey) {
                    let year = parseInt(monthKey.slice(0, 4))
                    let month = parseInt(monthKey.slice(4))
                    let amount = year in clientStats.column_data && month in clientStats.column_data[year] ? clientStats.column_data[year][month] : 0

                    total += amount

                    return (<TableCell>{amount}</TableCell>)
                  })}
                  <TableCell>{ Math.floor(total / avgCounter) }</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}